import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "1024px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(66,66,66,0.7)",
  },
};

const ProductModal = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="product-modal">
      <button className="btn-cart" onClick={() => setModalShow(true)}>
        Details
      </button>
      <Modal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        style={customStyles}
      >
        <a
          className="btn-cart"
          style={{ float: "right" }}
          onClick={() => setModalShow(false)}
        >
          X
        </a>
        <div className="about">
          <div className="portion">
            <div className="text">
              <p className="title card-title" style={{ color: "black" }}>
                {props.info.name}
              </p>
              <p className="subtitle card-subtitle">{props.info.description}</p>
              {props.info.active && (
                <h4
                  style={{ alignSelf: "center" }}
                  className={
                    !props.info.quickclamps ? "text-green" : "text-orange"
                  }
                >
                  ${props.info.price}
                </h4>
              )}
              <Link
                to="/assets/size-chart.pdf"
                className="btn-cart"
                target="_blank"
                download
              >
                Size Chart
              </Link>

              {/* <Link className="btn">LEARN MORE</Link> */}
            </div>
            <img src={props.info.images[0]} alt="Product Image" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductModal;
