import React from 'react'

const ThankYou = () => {
  return (
    <div>
      <section className="thank-you">
        <p class="title" style={{ borderBottom: '1px solid white' }}>
          THANK YOU FOR YOUR ORDER
        </p>
        <p className="subtitle">
          We appreciate your business! Check your e-mail for your order details. If you have any questions regarding your
          order, please feel free to get ahold of us at (218) 296-2009.
        </p>
      </section>
    </div>
  )
}
export default ThankYou
