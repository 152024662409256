import React from 'react'
import './Dealers.css'
const Dealers = () => {
    return (
        <section id="about" className="about" style={{ margin: 0, width: '100%' }}>
            <p className="title">DEALERS</p>
            <div
                className="portion"
            >
                <div className="text">
                    <p className="title card-title">Pure Powersports</p>
                    <p className="subtitle card-subtitle">
                        1520 Hwy 2 & 52 BYP E<br />
                        Minot, ND 58701<br />
                        701-852-7874<br />
                        <a href="https://pureminot.com" target="_blank" rel="noopener noreferrer">www.pureminot.com</a>
                    </p>
                </div>
                <img src="img/dealers/pure-powersports.png" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>
            <div
                className="portion "
            >
                <div className="text">
                    <p className="title card-title">Advantage RV's & Trailers Inc.</p>
                    <p className="subtitle card-subtitle">
                        922 23rd St SW<br />
                        Watertown, SD 57201<br />
                        605-753-5022<br />
                        <a href="https://advantagervs.com" target="_blank" rel="noopener noreferrer">www.advantagervs.com</a>
                    </p>
                </div>
                <img src="img/dealers/advantage-rvs.png" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>

            <div
                className="portion"
            >
                <div className="text">
                    <p className="title card-title">Ice Forts Custom Fish Houses</p>
                    <p className="subtitle card-subtitle">
                        16543 State Highway 371E<br />
                        Brainerd, MN 56401<br />
                        218-824-1722<br />
                        <a href="https://www.iceforts.com" target="_blank" rel="noopener noreferrer">www.iceforts.com</a>
                    </p>
                </div>
                <img src="img/dealers/ice-forts.png" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>

            <div
                className="portion "
            >
                <div className="text">
                    <p className="title card-title">River City Sports</p>
                    <p className="subtitle card-subtitle">
                        3571 E Rosser Ave<br />
                        Bismarck, ND 58501<br />
                        701-223-9003<br />
                        <a href="https://rivercityboats.com" target="_blank" rel="noopener noreferrer">www.rivercityboats.com</a>
                    </p>
                </div>
                <img src="img/dealers/river-city.png" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>
            <div
                className="portion"
            >
                <div className="text">
                    <p className="title card-title">Keepers RV Center</p>
                    <p className="subtitle card-subtitle">
                        19989 584th Ave<br />
                        Mankato, MN 56001<br />
                        507-625-4647<br />
                        <a href="https://keepersrv.com" target="_blank" rel="noopener noreferrer">www.keepersrv.com</a>
                    </p>
                </div>
                <img src="img/dealers/keepers.gif" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>
            <div
                className="portion "
            >
                <div className="text">
                    <p className="title card-title">4 Season Sport Shop</p>
                    <p className="subtitle card-subtitle">
                        2301 Old W Main St<br />
                        Red Wing, MN 55066<br />
                        651-<br />
                        <a href="https://4seasonsports.com" target="_blank" rel="noopener noreferrer">www.4seasonsports.com</a>
                    </p>
                </div>
                <img src="img/dealers/four-seasons.jpg" alt="Dealer" style={{ maxHeight: '200px' }} />
            </div>

            <div
                className="portion"
            >
                <div className="text" style={{ width: '100%' }}>
                    <p className="title card-title">First Ice Outdoors</p>
                    <p className="subtitle card-subtitle">
                        6593 113th Ave NE<br />
                        Spicer, MN 56288<br />
                        320-212-9470<br />
                        <a href="mailto:firsticeoutdoors@hotmail.com" target="_blank" rel="noopener noreferrer">firsticeoutdoors@hotmail.com</a>
                    </p>
                </div>
                {/* <img src="img/pro-staff/mike_olson.jpg" alt="Dealer" style={{ maxHeight: '200px' }} /> */}
            </div>

        </section>
    )
}

export default Dealers
