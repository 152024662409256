import React, { useState, useEffect } from "react";
import Product from "../Product/Product";
import IceHouses from "../IceHouses/IceHouses";
import QuickClamps from "../QuickClamps/QuickClamps";
import Apparel from "../Apparel/Apparel";
const axios = require("axios");
const Products = ({ type }) => {
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    axios.get("/api/products").then((res) => {
      setProductsList(res.data.reverse());
    });
  }, []);
  return (
    <div>
      <section id="abstract">
        <img
          class="abstract"
          src="../img/abstract.png"
          style={{ margin: "0" }}
        />
      </section>
      {type === "ice-houses" && <IceHouses productsList={productsList} />}
      {type === "quickclamps" && <QuickClamps productsList={productsList} />}
      {type === "apparel" && <Apparel productsList={productsList} />}
    </div>
  );
};

export default Products;
