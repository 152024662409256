import React from "react";

const Footer = () => {
  return (
    <footer>
      <div class="our-brands footer-logos">
        <a href="htps://backwaterangling.com" target="_blank">
          <img class="affiliate-logo" src="img/affiliates/bwa.jpeg" />
        </a>
        <a href="https://intelli-core.com" target="_blank">
          <img class="affiliate-logo" src="img/affiliates/intelli-core.jpg" />
        </a>
        <a href="https://www.anthemwheels.com/" target="_blank">
          <img class="affiliate-logo" src="img/affiliates/anthem.png" />
        </a>
        <a href="https://www.iceholepower.com/" target="_blank">
          <img class="affiliate-logo" src="img/affiliates/icehole-power.jpg" />
        </a>
        <a href="https://www.gstrailers.com/" target="_blank">
          <img class="affiliate-logo" src="img/affiliates/gstrailers.png" />
        </a>
      </div>
      <div className="footer-main">
        <div>
          <p style={{ textAlign: "center", borderBottom: "1px solid black" }}>
            CALL US
          </p>
          <p className="title card-title" style={{ color: "black" }}>
            The BlackHole Outdoors
          </p>
          <p>
            208 East Mason Avenue
            <br />
            Verndale, Minnesota 56481
            <br />
            United States
          </p>
          <a href="tel:(218) 296-2009">(218) 296-2009</a>
        </div>
        <div>
          <p style={{ textAlign: "center", borderBottom: "1px solid black" }}>
            WORK HOURS
          </p>
          <p className="title card-title" style={{ color: "black" }}>
            MONDAY - FRIDAY
          </p>
          <p>08:00 AM - 05:00 PM</p>
          <p className="title card-title" style={{ color: "black" }}>
            SATURDAY
          </p>
          <p>By appointment</p>
          <p className="title card-title" style={{ color: "black" }}>
            SUNDAY
          </p>
          <p>Closed</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

