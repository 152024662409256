import React from 'react'

const Bio = () => {
  return (
    <div>
      <section id="abstract">
        <img
          alt="Abstract Banner"
          className="abstract"
          src="img/abstract.png"
          style={{ margin: '0' }}
        />
      </section>
      <section className="about">
        <p className="pretitle">CEO OF THE BLACKHOLE OUTDOORS LLC</p>
        <p className="title">JASON GEIS</p>
        <div className="portion" style={{margin: '0'}}>
          <div className="text" style={{width: '100%'}}>
            {/* <p className="title card-title"></p> */}
            <p className="subtitle card-subtitle" style={{textAlign: 'justify'}}>
              My name is Jason Geis, and in the beginning I never thought the
              black hole ice shack would become what it has. All I was thinking
              about when I designed it was how can I simplify taking my boys out
              fishing, while I was working at Lund boats I would come across
              different ideas and other great products on social media and they
              were all great ideas but they just didn't fit what I needed. So as
              the years passed on and ice fishing came and gone i couldn't get
              these ideas out of my head and every spring I said to myself that
              I was going to build one but before you knew it summer was gone
              and we were looking at another hard-water season with the same
              struggles. One night I was sitting down with my dad just chatting
              about how he was thinking of building a fish house frame since he
              was retired and was looking for things to do with his time and I
              jokingly threw out this crazy idea I had been thinking about for
              years. He liked so we decided to finally build one. We had no idea
              how fast it would take off... It was a little overwhelming, I
              never dreamed that it would become what it has. We are so thankful
              for all the support we have received from everyone in the industry
              and feel so honored to be able to create a product that so many
              people have been able to enjoy with their friends and family!
            </p>
          </div>
          <img src="img/bio.jpeg" alt="Jason Geis Profile Picture" height="400px" />
          {/* <div className="line-v"></div> */}
        </div>
      </section>
    </div>
  )
}

export default Bio
