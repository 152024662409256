import React, { useEffect, useState, useContext } from "react";
import { CartContext } from "../../contexts/CartContext";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
const Navbar = () => {
  const { itemCount } = useContext(CartContext);
  const [active, setActive] = useState("home");
  useEffect(() => {
    const burger = document.querySelector(".burger");
    const nav = document.querySelector(".nav-links");
    const navLinks = document.querySelectorAll(".nav-links li");
    const play = document.querySelector(".player");

    navLinks.forEach((navLink) => {
      navLink.addEventListener("click", () => {
        nav.classList.toggle("nav-active");
        burger.classList.toggle("burger-active");
        nav.style.display = window.screen.width > 768 ? "flex" : "none";
      });
    });

    burger.addEventListener("click", () => {
      //Nav toglge
      nav.classList.toggle("nav-active");
      if (play) play.classList.toggle("hide");
      //Burger
      nav.style.display = burger.classList.toggle("burger-active")
        ? "flex"
        : "none";
    });
  }, []);

  return (
    <header className="main-header">
      <Link to="/" className="logo">
        <img
          // src={process.env.PUBLIC_URL + '/img/logo-outdoors.png'}
          src={process.env.PUBLIC_URL + "/img/ice_shack.png"}
          alt="Logo"
        />
      </Link>
      <nav className="main-nav">
        <ul className="nav-links">
          <li>
            <Link
              to="/"
              className={active === "home" ? "active" : ""}
              onClick={() => setActive("home")}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/dealers"
              className={active === "dealers" ? "active" : ""}
              onClick={() => setActive("dealers")}
            >
              DEALERS
            </Link>
          </li>
          <li>
            <Link
              to="/our-story"
              className={active === "our-story" ? "active" : ""}
              onClick={() => setActive("our-story")}
            >
              OUR STORY
            </Link>
          </li>

          <li>
          <a
              href="https://products.theblackholeoutdoors.com/collections/"
            >
              Products
            </a>
            {/* <div class="dropdown">
              <a class={active === "products" ? "active" : ""} href="#">
                Products  
                &nbsp;<i class="fa fa-caret-down text-green"></i>
              </a>
              <div class="dropdown-content">
                <Link to="/products/ice-houses">Ice Houses</Link>
                <Link to="/products/quick-clamps">Quick Clamps</Link>
                <Link to="/products/apparel">Apparel</Link>

              </div>
            </div> */}

          
          </li>

          <li>
            <Link
              className={active === "pro-staff" ? "active" : ""}
              to="/pro-staff"
              onClick={() => setActive("pro-staff")}
            >
              Pro Staff
            </Link>
          </li>

          <li>
            <HashLink to="/#contact" onClick={() => setActive("home")}>
              Contact
            </HashLink>
          </li>
          <li>
          <a
              href="https://products.theblackholeoutdoors.com/blogs/main"
            >
              Blog
            </a></li>
          {/* <li>
            <Link
              className={active === "cart" ? "active" : ""}
              to="/cart"
              onClick={() => setActive("cart")}
            >
              <i className="fa fa-shopping-cart fa-2x"></i>&nbsp;&nbsp;(
              {itemCount})
            </Link>
          </li> */}
        </ul>
        <div className="burger">
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
