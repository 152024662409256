import React, { useEffect } from "react";
import { setUpLightbox } from "../../helpers/skewteaser.js";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  useEffect(() => setUpLightbox(), []);
  const sendMail = () => {
    window.Email.send({
      SecureToken: "861bec88-55fe-42ee-8f97-61b1a08b4265",
      To: "geis669@gmail.com",
      FromName: "TheBlackHoleOutdoors.com",
      From: 'geis669@gmail.com',
      ReplyAddress: document.getElementById("email").value,
      Subject: "[#" + Date.now() + "] Contact inquiry",
      Body:
        "<html><p>Email: </p><strong>" +
        document.getElementById("email").value +
        "</strong><br/><p>Message: </p><strong>" +
        document.getElementById("message").value +
        "</strong></html>",
    })
      .then((message) => alert("Message successfully sent!"))
      .catch((err) => alert(err));
  };
  return (
    <div className="home">
      <a className="lightbox" href="https://player.vimeo.com/video/392540843">
        <div
          id="hero"
          className="hero"
          style={{ backgroundImage: "url('../img/hero.jpg')" }}
        >
          <div className="player">
            <span>Play</span>
          </div>
        </div>
      </a>
      <div id="our-brands" className="our-brands">
        <img src="img/outdoors.png" alt="TheBlackHole Outdoors" />
        {/* <img src="img/logo-shack.png" alt="Ice Shack" /> */}
        <img src="img/ice_shack.png" alt="TheBlackHole IceShack" />
        {/* <Link smooth to="/products/quick-clamps">
          <img src="img/qc.png" alt="QuickClamps" />
        </Link> */}
        <a
          href="https://products.theblackholeoutdoors.com/collections/quick-clamps"
          target="_blank"
        >
          <img src="img/qc.png" alt="QuickClamps" />
        </a>
      </div>
      <section id="about" className="about">
        <p className="pretitle">WELCOME TO THE FUTURE</p>
        <p className="title">ABOUT</p>
        <div className="portion right">
          <div className="text">
            <p className="title card-title">Hybdrid Ice Shack</p>
            <p className="subtitle card-subtitle">
              Our fish houses give you the portability of a flip over and the
              durability of a wheel house while being able to haul your UTV &
              ATV like a toy hauler!
            </p>
            {/* <Link className="btn" style={{ width: '50%' }}>
              LEARN MORE
            </Link> */}
          </div>
          <img src="img/xl-shack/1.jpg" alt="Ice House XL Shack" />
          <div className="line-v"></div>
        </div>
        <div className="portion">
          <div className="text">
            <p className="title card-title">More</p>
            <p className="subtitle card-subtitle">
              There has never been such a versatile set up like this in the hard
              water industry - from fishing, hunting, camping and the ability to
              use a trailer all year round. The possibilities are endless!
            </p>
            {/* <Link className="btn">LEARN MORE</Link> */}
          </div>
          <img src="img/xl-shack/2.jpg" alt="Ice Shack"/>
          <div className="line-v"></div>
        </div>
      </section>
      <section id="abstract">
        <img className="abstract" alt="Abstract Banner" src="img/abstract.png" />
      </section>

      <section id="contact" className="contact">
        <p className="pretitle">CONTACT US</p>
        <p className="title">DROP US A LINE</p>
        <input type="text" id="email" placeholder="E-mail" />
        <br />
        <textarea
          id="message"
          name="message"
          rows="10"
          cols="50"
          placeholder="Message"
        ></textarea>
        <a
          href="javascript:{}"
          className="btn"
          onClick={sendMail}
          style={{ margin: "3% 0" }}
        >
          SEND
        </a>
      </section>
    </div>
  );
};

export default Home;
