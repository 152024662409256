const setUpGallery = () => {
  var teasers = document.querySelectorAll('.teaser-skew-gallery')
  teasers.forEach(function (teaser) {
    // Gallery Attributes
    const hasGalleryLeft = teaser.classList.contains('skew-gallery-left')

    // Gallery Content
    const skewSideLeft = teaser.querySelector('.skew-side-left')
    const skewSideRight = teaser.querySelector('.skew-side-right')

    const gallery = teaser.querySelector('.skew-gallery')
    const galleryEls = gallery.querySelectorAll('li')
    const percentageWith = Math.round(100 / galleryEls.length)

    // HANDLE: Gallery element positioning
    galleryEls.forEach(function (gEl, index) {
      let newPercentage = '' + index * percentageWith + '%'
      gEl.style.left = hasGalleryLeft ? '-' + newPercentage : newPercentage
    })

    // HANDLE: skew-sides hover
    skewSideLeft.addEventListener('mouseover', function () {
      teaser.classList.add('skewSideLeftHover')
    })
    skewSideLeft.addEventListener('mouseleave', function () {
      teaser.classList.remove('skewSideLeftHover')
    })
    skewSideRight.addEventListener('mouseover', function () {
      teaser.classList.add('skewSideRightHover')
    })
    skewSideRight.addEventListener('mouseleave', function () {
      teaser.classList.remove('skewSideRightHover')
    })

    // HANDLE: Gallery Elements Hover
    galleryEls.forEach(function (galEl, index) {
      const realIndex = index + 1
      galEl.addEventListener('mouseover', function () {
        gallery.classList.add('el-' + realIndex + '-hover')
      })
      galEl.addEventListener('mouseleave', function () {
        gallery.classList.remove('el-' + realIndex + '-hover')
      })
    })
  })
  /// END: Skew Gallery Handling
  setUpLightbox()
}
const setUpLightbox = () => {
  new window.SimpleLightbox({ elements: 'a.lightbox' })
}
module.exports = { setUpGallery, setUpLightbox }
