import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Products from "./components/Products/Products";
import Cart from "./components/Cart/Cart";
import Home from "./components/Home/Home";
import LandingPage from "./components/LandingPage/LandingPage";
import { iceHousesList } from "./helpers/productsHelper";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProStaff from "./components/ProStaff/ProStaff";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import NewProducts from "./components/NewProducts/NewProducts";
import Dealers from "./components/Dealers/Dealers";
import ThankYou from "./components/ThankYou/ThankYou";
import Bio from "./components/Bio/Bio";
function App() {
  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
      <ScrollToTop />
      <Navbar />
      <Switch>
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/products/ice-houses"*/}
        {/*  render={() => <Products type="ice-houses" />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/products/quick-clamps"*/}
        {/*  render={() => <Products type="quickclamps" />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/products/apparel"*/}
        {/*  render={() => <Products type="apparel" />}*/}
        {/*/>*/}
        <Route exact path="/" component={Home} />
        {/*<Route path="/cart" component={Cart} />*/}
        <Route exact path="/pro-staff" component={ProStaff} />
        {/* <Route exact path="/new-2021" component={NewProducts} /> */}
        <Route exact path="/dealers" component={Dealers} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/our-story" component={Bio} />
        {/* redirect /products to a different url */}
        <Route path="/products" component={() => {
          window.location.href = "https://products.theblackholeoutdoors.com/collections";
          return null;
        }} />


        {/*{iceHousesList.map((iceHouse) => {*/}
        {/*  return (*/}
        {/*    <Route*/}
        {/*      exact*/}
        {/*      path={"/products/" + iceHouse.id}*/}
        {/*      render={(props) => <LandingPage {...props} product={iceHouse} />}*/}
        {/*    />*/}
        {/*  );*/}
        {/*})}*/}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
