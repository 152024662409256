import React from 'react'
import './ProStaff.css'
const ProStaff = () => {
  return (
    <section id="about" className="about" style={{ margin: 0 }}>
      <p className="pretitle">MEET OUR</p>
      <p className="title">PRO STAFF</p>
      <div
        className="portion right"
        style={{ flexDirection: 'column-reverse' }}
      >
        <div className="text">
          <p className="title card-title">Mike Olson</p>
          <p className="subtitle card-subtitle">
            Fishing has always had a special place in my heart. It started as
            far back as I could remember fishing in Colorado when I was a kid to
            when my grandfather started taking me ice fishing. I've continued to
            hold the passion of fishing close to me as I've gotten older. I have
            always looked at fishing as a way to give back to those around me.
            You can find me on Backwater Angling or near a local lake/river.
          </p>
        </div>
        <img src="img/pro-staff/mike_olson.jpg" alt="Mike Olson" />
        <div className="line-v"></div>
      </div>
      <div className="portion">
        <div className="text">
          <p className="title card-title">Kyle Thompson</p>
          <p className="subtitle card-subtitle">
            As a boy growing up my dad would always take me fishing with him in
            his 14’ aluminum boat. As my love for the sport grew we started
            fishing more and more, and eventually took up fly fishing when I
            moved to Winona, MN in the Driftless Region for college. Fishing is
            my greatest passion and I greatly enjoy traveling to fish. So far, I
            have fished in 13 states including Texas for Alligator Gar, Colorado
            and Wyoming for Stream Trout and a fly in trip to Alaska for Coho
            Salmon. Last winter a few of my friends who I frequently fish and
            travel with decided we wanted to take our passion to the next level
            and we created Backwater Angling. At Backwater Angling our goal is
            to share our outdoor adventures with the followers of our various
            social media platforms!
          </p>
        </div>
        <img src="img/pro-staff/kyle_thompson.jpg" alt="Kyle Thompson" />
        <div className="line-v"></div>
      </div>
      <div className="portion right">
        <div className="text">
          <p className="title card-title">Brooks Hondl</p>
          <p className="subtitle card-subtitle">
            Brooks started out fishing as a youngster with his grandfather in
            Dickinson, North Dakota. He fished the local heart River, And small
            local Lakes. He fished for anything that would bite as he was
            growing up. As his Grandfather aged, Brooks started going out
            fishing on his own more. As his knowledge of fishing grew he started
            to target Walleye, Pike, and panfish. Brooks now travels all over to
            fish Lake of the woods, upper red lake, leech lake, Lake Winnipeg,
            Lake Sakakawea, Lake Tschida, Devils lake and surrounding lakes. You
            can say he became a fish addict. From open water fishing to ice
            fishing there isn’t a time he stops fishing.
          </p>
        </div>
        <img src="img/pro-staff/brooks_hondl.jpg" alt="Brooks Hondl" />
        <div className="line-v"></div>
      </div>
    </section>
  )
}

export default ProStaff
